import axios from 'axios'
import {toastController} from '@ionic/vue';
import {getToken} from "@/utils/auth";

// 创建实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 50000
});

// 添加请求拦截器
service.interceptors.request.use(config => {
    if (config.method === 'get') {
        config.params = {
            _t: new Date().getTime(),
            ...config.data
        }
    }
    config.headers = {
        'Cache-Control': 'no-cache',
        'token': getToken(),
    }
    return config
}, error => {
    Promise.reject(error)
});

// 添加response拦截器
service.interceptors.response.use(response => {
    const data = response.data;
    const code = parseInt((data as any).code);

    // 状态码白名单，如果在白名单内，则不会出现错误弹窗
    if (code === 200) {
        return data;
    } else {
        toastController.create({
            message: (data as any).msg,
            duration: 2000
        }).then();
        return Promise.reject(response)
    }
}, error => {
    if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) { // 数据请求超时
        toastController.create({
            message: "数据请求超时！",
            duration: 2000
        }).then();
    } else {
        toastController.create({
            message: "系统错误！",
            duration: 2000
        }).then();
    }
});

export default service;
