/**
 * SessionStorage和LocalStorage的封装
 */
const storage = {
    setLocal(key: string, value: string | number | boolean | any[]) {
        window.localStorage.setItem(key, value == null ? '""' : JSON.stringify(value))
    },
    getLocal(key: string) {
        const value: any = window.localStorage.getItem(key) || null;
        return value ? JSON.parse(value) : ''
    },
    clearOneLocal(key: string) {
        window.localStorage.removeItem(key)
    },
    clearAllLocal() {
        window.localStorage.clear()
    }
};

export default storage
