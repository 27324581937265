import storage from './storage'

const ACCESS_TOKEN = 'token';
const IS_LOGIN = 'isLogin';

export function setToken(token: any): void {
    storage.setLocal(ACCESS_TOKEN, token);
}

export function getToken(): '' | string {
    console.log(ACCESS_TOKEN);
    console.log(storage.getLocal(ACCESS_TOKEN));
    return storage.getLocal(ACCESS_TOKEN)
}

export function removeToken(): void {
    storage.clearOneLocal(ACCESS_TOKEN);
}

export function isLogin(): boolean {
    return storage.getLocal(IS_LOGIN) !== "";
}
